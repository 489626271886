import * as React from 'react'

import { Row, Typography, Icon, Column } from '@wicadu/arepa/ui'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

import Consts from '@utils/constants'

interface Props {
  title: string
  description?: string
  icon: string
  url?: string
  disabled?: boolean
  className?: string
}

const defaultProps: Partial<Props> = {
  title: '',
  description: '',
  icon: '',
  url: '',
  className: '',
}

function ContactMethod(props: Props) {
  const { title, description, icon, disabled, url, className } = {
    ...defaultProps,
    ...props,
  }

  return (
    <StyledLink to={url} aria-disabled={disabled} disabled={disabled}>
      <Container disabled={disabled} className={className} gap={10}>
        <StyledIcon name={icon} size={40} withBackground={0.45} />

        <StyledColumn>
          <Typography
            type="default"
            size={16}
            weight={700}
            numberOfLines={1}
            children={title}
          />
          <Typography
            type="description"
            size={12}
            numberOfLines={1}
            children={description}
          />
        </StyledColumn>

        <Icon name="arrow_forward_ios" size={12} />
      </Container>
    </StyledLink>
  )
}

const { MEDIUM_DEVICE } = Consts.breakpoints

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const Container = styled(Row)`
  border-radius: 10px;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.NEUTRAL.SIDE};
  width: 100%;
  text-decoration: inherit;
  cursor: pointer;

  ${({ disabled }) => disabled && 'opacity: 0.6;'}
`

const StyledColumn = styled(Column)`
  margin-right: auto;
`

const StyledIcon = styled(Icon)`
  ${({ name }) => !name && 'display: none;'}

  min-width: 40px;

  @media screen and (min-width: ${MEDIUM_DEVICE}px) {
    min-width: 40px;
    width: 40px;
    height: 40px;
    font-size: 25px;
  }
`

export default ContactMethod
