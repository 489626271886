import React, { useMemo } from 'react'

import type { PageProps } from 'gatsby'

import ContactUsTemplate from '@templates/ContactUs'
import { TemplateProvider } from '@hooks/useTemplate'
import HeadSEO from '@atoms/HeadSEO'
import { PAGES_ID } from '@config/pages/const'
import { usePageContext } from '@HOCs/Providers/PageContext'
import Consts from '@utils/constants'
import { type IPageProps } from '@ts-types/Page'
import { getUrlByPageId } from '@utils/getUrlByPageId'
import { type Languages } from '@ts-types/Translations'
import { type ContactMethod } from '@ts-types/ContactMethod'
import useAuth from '@hooks/useAuth'

interface Props extends IPageProps {}

const { CONTACT_US_TRANSLATIONS } = Consts.translates

const ContactUsPage: React.FC<PageProps> = () => {
  const { lang } = usePageContext()
  const { isSignedIn } = useAuth()

  const methodsData = useMemo(
    () => ({
      MAIL: {
        icon: 'mail',
        disabled: false,
        url: 'mailto:support@wicadu.com',
      },
      CALL: {
        icon: 'call',
        disabled: true,
        url: '',
      },
      MESSAGE: {
        icon: 'message',
        disabled: true,
        url: '',
      },
    }),
    [isSignedIn]
  )

  const createMethodItem = (method: ContactMethod, lang: Languages) => ({
    icon: methodsData[method].icon,
    title: CONTACT_US_TRANSLATIONS[lang].PAGE['METHODS'][method].TITLE,
    description:
      CONTACT_US_TRANSLATIONS[lang].PAGE['METHODS'][method].DESCRIPTION,
    disabled: methodsData[method].disabled || !isSignedIn,
    url: !isSignedIn ? '' : methodsData[method].url,
  })

  const methods = [
    {
      title: CONTACT_US_TRANSLATIONS[lang].PAGE['RECOMMENDED'],
      items: ['MAIL'].map((method) =>
        createMethodItem(method as ContactMethod, lang)
      ),
    },
    {
      title: CONTACT_US_TRANSLATIONS[lang].PAGE['OTHER_OPTIONS'],
      items: ['CALL', 'MESSAGE'].map((method) =>
        createMethodItem(method as ContactMethod, lang)
      ),
    },
  ]

  return (
    <TemplateProvider
      value={{
        translate: CONTACT_US_TRANSLATIONS,
        lang,
        methods,
        isSignedIn,
      }}
    >
      <ContactUsTemplate />
    </TemplateProvider>
  )
}

export function Head({ pageContext }: Props) {
  const { lang } = pageContext || {}

  const { organization } = Consts.schemeOrg

  return (
    <HeadSEO
      title={CONTACT_US_TRANSLATIONS[lang].SEO_TITLE as string}
      description={CONTACT_US_TRANSLATIONS[lang].SEO_DESCRIPTION as string}
      lang={lang}
      pageId={PAGES_ID.CONTACT_US}
      jsonLd={[
        {
          '@type': 'WebSite',
          '@id': getUrlByPageId(PAGES_ID.HOME, lang),
          name: CONTACT_US_TRANSLATIONS[lang].SEO_TITLE,
          description: CONTACT_US_TRANSLATIONS[lang].SEO_DESCRIPTION,
          url: getUrlByPageId(PAGES_ID.HOME, lang),
          publisher: organization.organizationField,
        },
        {
          '@type': 'ContactPage',
          url: getUrlByPageId(PAGES_ID.CONTACT_US, lang),
          headline: CONTACT_US_TRANSLATIONS[lang].SEO_TITLE,
          description: CONTACT_US_TRANSLATIONS[lang].SEO_DESCRIPTION,
          mainEntity: organization.organizationField,
          publisher: organization.organizationField,
        },
      ]}
    />
  )
}

export default ContactUsPage
