import * as React from 'react'

import { Typography, Section, FlatList } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import useTemplate from '@hooks/useTemplate'
import Layout from '@HOCs/Layout/Layout'
import Consts from '@utils/constants'
import { ContactUsScreen } from '@ts-types/Pages/ContactUs'
import ContactMethod from '@molecules/ContactMethod'
import AuthCallToAction from '@molecules/AuthCallToAction'

function ContactUsTemplate() {
  const { translate, lang, methods } =
    useTemplate<ContactUsScreen>()

  return (
    <Layout>
      <Layout.PageGrid>
        <StyledHero>
          <StyledTitle
            type="title"
            size={10}
            align="right"
            children={translate[lang].PAGE['TITLE'] as string}
          />
          <Typography
            type="title-2"
            size={32}
            align="center"
            children={translate[lang].PAGE['DESCRIPTION'] as string}
          />
        </StyledHero>

        <AuthCallToAction />

        {methods.map(({ title, items }) => (
          <StyledSection
            title={title}
            titleProps={{ type: 'title-3', size: 16 }}
          >
            <FlatList
              data={items}
              keyExtracted="index"
              component={ContactMethod}
            />
          </StyledSection>
        ))}
      </Layout.PageGrid>
    </Layout>
  )
}

const { SMALL_DEVICE } = Consts.breakpoints

const StyledHero = styled.header`
  margin: 0 auto;
`

const StyledTitle = styled(Typography)`
  @media screen and (min-width: ${SMALL_DEVICE}px) {
    font-size: 14px !important;
    line-height: 18px !important;
  }
`

const StyledSection = styled(Section)`
  width: 100%;
  margin: auto;
  gap: 5px;

  @media screen and (min-width: ${SMALL_DEVICE}px) {
    width: ${SMALL_DEVICE * 0.75}px;
    gap: 5px;

    [data-title='true'] {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

export default ContactUsTemplate
